import * as React from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";

const KamsaPage = () => (
  <Layout>
    <div className="container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>How It Works - Compensation Software Demo - Kamsa</title>
        <meta property="og:title" content="How It Works - Compensation Software Demo - Kamsa" />
        <meta name="description" content="See how Kamsa makes compensation management painless with our employee compensation, benchmarking, budgeting, review and planning tools." />
      </Helmet>
      <div className="section is-medium has-text-centered">
        <div className="block">
          <h1 className="title is-h1">How Kamsa Works</h1>
        </div>
        <iframe style={{ maxWidth: '100%' }} width="1000" height="600" src="https://www.youtube.com/embed/S7kvfkwfC1Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
  </Layout>
);

export default KamsaPage;
